<template>
  <section>
    <div class="background-section-title">
      <div class="section-title">
        <h2>Download</h2>
      </div>
    </div>
    <div class="section-content">
      <div class="list-container">
       <b-card class="card-text shadow-sm p-3 mb-5 bg-white rounded">
         <b-card-title class="card-title">
           Requisitos do Sistema
         </b-card-title>
         <b-row>
           <b-col cols="5">
             <b-card-text>
               <strong style="font-family: Poppins;">Sistema Operacional</strong>
             </b-card-text>
           </b-col>
           <b-col>
           <b-card-text class="card-text-2">
             Windows 7 ou Superior
           </b-card-text>
           </b-col>
         </b-row>
         <b-row>
            <b-col cols="5">
                    <b-card-text>
                      <strong style="font-family: Poppins;">Arquitetura</strong>
                    </b-card-text>
            </b-col>
            <b-col>
                    <b-card-text class="card-text-2">
                      64-bits
                    </b-card-text>
            </b-col>
         </b-row >
         <b-button :href="url" class="button-download" variant="primary" >
           <img src="../assets/icons/download-app.svg" width="30" />
           Retextoar Cliente Versão {{appVersion}}</b-button>
         </b-card>
      </div>
    </div>
  </section>
</template>

<script>
  import { retextoarAppService } from '../services/retextoar-app.service';
  import config from '../config/config.json';
  import 'vue-loading-overlay/dist/vue-loading.css';
  export default {
    data () {
      return {
        url: ''
      };
    },
    async created () {
      const result = await retextoarAppService.getAppVersion();
      if (result) {
        const appVersion = result.version;
        const storage = config.storageAddress;
        this.url = `${storage}/retextoar-cliente_${appVersion}.exe`;
      }
    },
    beforeDestroy () {
    },
    methods: {
    }
  };
</script>

<style lang="scss" scoped>
  .section-content {
    display: flex;
  }

    .list-container {
      margin-left: 20px;
      margin-right: 20px;
      flex: 1;
  }

  .card-title {
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    color: #207BDD;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 55px;

  }

  .card-text {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    word-wrap: break-word;
    min-height: 70px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #6278A3;
    .col-5 {
      max-width: 30%;
    }
  }

  .card-text-2 {
    font-weight: 100;
    font-family: Poppins;
  }

  .button-download {
    height: 45px;
    width: 625px;
    padding: 6px 12%;
  }

</style>
