import config from '../config/config.json';
import { serverURL, handleRequest } from '../helpers/server';
const storage = config.storageAddress;

export const retextoarAppService = {
  getAppVersion,
  downloadApp
};

async function getAppVersion () {
  const requestOptions = {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    timeout: 6000
  };
  return await handleRequest(`${serverURL()}/portal/v1/downloads/stt/app/version`, requestOptions);
}

async function downloadApp (appVersion) {
  const requestOptions = {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    timeout: 6000
  };
  return await handleRequest(`${storage}/retextoar-cliente_${appVersion}.exe`, requestOptions);
}
